<template>
    <div class="chat__message-upload">
        <span class="chat__message-upload-title">
            {{ data.name }}
        </span>
        <form @submit.prevent="submitDocuments()" class="chat__message-upload-form" ref="formDocuments">
            <mc-file :name="`file-${data.docId}`" :fileName="data.name" ref="file"></mc-file>

            <mc-button secondary pen @click="showManualField()" v-if="data.manually">
                {{ __('Zadať ručne') }}
            </mc-button>

            <mc-textarea
                :name="`text-${data.docId}`"
                :placeholder="__('Zadajte hodnotu')"
                v-model="manualValue"
                v-show="manualField"
                ref="manual"
            ></mc-textarea>

            <mc-error v-show="error">{{ __('Musíte vyplniť minimálne jedno pole') }}</mc-error>

            <mc-button primary type="submit" :loading="currentApiState == 'loading'">
                {{ __('Odoslať') }}
            </mc-button>

            <div class="chat__message-btns-row">
                <mc-button secondary @click="fetchRequiredDocuments()" :loading="currentApiState == 'loading'">
                    {{ __('Vybrať iný typ dokumentu') }}
                </mc-button>
                <mc-button secondary @click="submitDocuments(true)" :loading="currentApiState == 'loading'">
                    {{ __('Dokončiť') }}
                </mc-button>
            </div>
        </form>
    </div>
</template>

<script>
import McButton from '@/components/form/McButton';
import McFile from '@/components/form/McFile';
import McTextarea from '@/components/form/McTextarea';
import McError from '@/components/form/McError';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: {
        McButton,
        McFile,
        McTextarea,
        McError,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            manualField: false,
            manualValue: '',
            error: false,
        };
    },
    computed: {
        ...mapState(['currentApiState']),
        ...mapState('Chat', ['hash']),
    },
    methods: {
        ...mapMutations('Chat', ['addQuestion']),
        ...mapActions('Chat', ['fetchRequiredDocuments']),
        submitDocuments(endChatbot = false) {
            let files = this.$refs.file.files,
                manualValue = this.$refs.manual.modelValue;

            if (files.length == 0 && !manualValue) {
                if (endChatbot) {
                    this.showSuggestionForm();
                    return;
                }

                this.error = true;
                return;
            } else {
                this.error = false;

                //send documents
                if (files.length > 0) {
                    this.submitRequiredDocuments(files, endChatbot);
                }

                //send text value
                if (manualValue) {
                    this.submitRequiredText(endChatbot);
                }
            }
        },
        showManualField() {
            this.manualField = !this.manualField;
            this.$nextTick(() => {
                this.$refs.manual.$el.children[0].focus();
            });
        },
        showSuggestionForm() {
            this.addQuestion({
                blocks: [{ type: 'ChatSuggestion' }],
            });
        },
        responseQuestions() {
            this.addQuestion({
                blocks: [{ type: 'ChatText', data: 'Nahrané dokumenty som úspešne odoslal do poisťovne' }],
            });

            this.addQuestion({
                blocks: [
                    { type: 'ChatTitle', data: 'Ako si prajete pokračovať?' },
                    {
                        type: 'ChatButtons',
                        data: [
                            {
                                text: 'Nahrať ďalší dokument',
                                action: 'Chat/fetchRequiredDocuments',
                                params: null,
                                type: 'primary',
                            },
                            {
                                text: 'Dokončiť a uzavrieť',
                                mutation: 'Chat/addQuestion',
                                params: {
                                    blocks: [{ type: 'ChatSuggestion' }],
                                },
                                type: 'secondary',
                            },
                        ],
                    },
                ],
            });
        },
        async submitRequiredDocuments(files, endChatbot) {
            let formData = new FormData();

            Array.from(files).forEach((file) => formData.append('soubor', file.file));

            try {
                await window.$axios.post(`${this.hash}/requiredDocs/${this.data.docId}/file`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (endChatbot) {
                    this.showSuggestionForm();
                } else {
                    this.responseQuestions();
                }
            } catch (error) {
                throw new Error('Submit RequiredDocs ' + error.error + ' / msgCode: ' + error.msgCode);
            }
        },
        async submitRequiredText(endChatbot) {
            let xml = `<xml-string-response xmlns="www.ais.cz" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="www.ais.cz XmlStringResponse.xsd">
                        <value>${this.manualValue}</value>
                    </xml-string-response>`;
            try {
                await window.$axios.post(`${this.hash}/requiredDocs/${this.data.docId}/text`, xml, {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                });

                if (endChatbot) {
                    this.showSuggestionForm();
                } else {
                    this.responseQuestions();
                }
            } catch (error) {
                throw new Error('Submit RequiredText ' + error.error + ' / msgCode: ' + error.msgCode);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-upload-title {
    color: $orange;
    margin: 0.5rem 0 1rem 0;
    display: flex;
    align-items: center;
    font-weight: $fwBold;
    position: relative;
    font-size: 1.2rem;
    line-height: 1.7rem;

    &:before {
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        background: $orange;
        mask: url($basePath + 'icons/document.svg') no-repeat center;
        margin-right: 1rem;
    }
}

.chat__message-upload-form {
    & > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.chat__message-btns-row {
    display: flex;
    margin: 0 -0.5rem;

    @include less-than(sm) {
        flex-direction: column;
        margin: 0;
    }

    &:deep(> *) {
        margin: 0 0.5rem;

        @include less-than(sm) {
            margin: 0.5rem 0;
        }
    }
}
</style>
