import axios from 'axios';

const getBaseUrl = () => {
    if (window.location.pathname.includes('resttest1')) {
        return process.env.VUE_APP_BASE_API_URL_TEST1;
    } else if (window.location.pathname.includes('resttest2')) {
        return process.env.VUE_APP_BASE_API_URL_TEST2;
    }

    return process.env.VUE_APP_BASE_API_URL;
};

const $axios = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

$axios.interceptors.request.use(function (request) {
    window.$store.commit('setCurrentApiState', 'loading');

    return request;
});

const errorInterceptor = (error) => {
    let msgCode = error?.response?.data?.runtimeMsgs ? error.response.data.runtimeMsgs[0].msgCode : null,
        newError = {
            error: error,
            msgCode: msgCode,
        };

    window.$store.commit('setCurrentApiState', {
        type: 'error',
        msgCode: msgCode,
    });

    return Promise.reject(newError);
};

const responseInterceptor = (response) => {
    window.$store.commit('setCurrentApiState', null);

    return response;
};

$axios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default $axios;
