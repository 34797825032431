<template>
    <div class="mc-error">
        <slot />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.mc-error {
    color: $white;
    font-size: 1.3rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    background: $darkGray;
    @include border-radius($rad / 2);

    &:before {
        content: '';
        width: 1.8rem;
        height: 1.8rem;
        background: $white;
        mask: url($basePath + 'icons/error.svg') no-repeat center;
        mask-size: 100% auto;
        margin-right: 1rem;
    }
}
</style>
