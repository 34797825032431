<template>
    <div class="chat__message-darkbox">
        <strong>{{ data }}</strong>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-darkbox {
    display: block;
    padding: 1rem;
    font-size: 1.3rem;
    line-height: 2rem;
    margin-top: 1rem;
    background: darken($lightGray, 10);
    @include border-radius($rad / 2);

    @include less-than(md) {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}
</style>
