import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import _ from 'lodash';

import chatStore from './modules/chat.module';

var hash = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

export default createStore({
    state: {
        currentApiState: null,
    },
    getters: {
        isLoading: (state) => {
            return state.currentApiState == 'loading';
        },
        isError: (state) => {
            return _.isObject(state.currentApiState) && state.currentApiState.type == 'error';
        },
    },
    mutations: {
        setCurrentApiState(state, val) {
            state.currentApiState = val;
        },
    },
    actions: {},
    modules: {
        Chat: chatStore,
    },
    plugins: [
        createPersistedState({
            key: hash,
            storage: window.localStorage,
        }),
    ],
});
