<template>
    <mc-button primary @click="resetChat()">
        {{ __('Začať znova') }}
    </mc-button>
</template>

<script>
import McButton from '@/components/form/McButton';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: {
        McButton,
    },
    computed: {
        ...mapState('Chat', ['hash']),
    },
    methods: {
        ...mapMutations('Chat', ['resetState', 'resetQuestions', 'setHash']),
        ...mapActions('Chat', ['fetchIntro']),
        resetChat() {
            let hash = this.hash;
            this.resetState();
            this.resetQuestions();
            this.setHash(hash);
            this.fetchIntro();
        },
    },
};
</script>

<style lang="scss" scoped></style>
