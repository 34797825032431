<template>
    <div class="chat__message-wrapper">
        <div class="chat__avatar">
            <leo-avatar />
        </div>
        <div class="chat__message" :class="{ '--inactive': question.state == 'inactive' }">
            <div :class="{ 'd-flex': question.avatar }">
                <leo-avatar large class="d-none d-md-block mr-2" v-if="question.avatar" />

                <div class="chat__message-inner">
                    <component v-for="(block, index) in question.blocks" :key="index" :is="block.type" :data="block.data" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeoAvatar from '@/components/chat/LeoAvatar';
import ChatTitle from '@/components/chat/message/type/ChatTitle';
import ChatText from '@/components/chat/message/type/ChatText';
import ChatDarkbox from '@/components/chat/message/type/ChatDarkbox';
import ChatSuggestion from '@/components/chat/message/type/ChatSuggestion';
import ChatButtons from '@/components/chat/message/type/ChatButtons';
import ChatDocuments from '@/components/chat/message/type/ChatDocuments';
import ChatUpload from '@/components/chat/message/type/ChatUpload';
import ChatResetButton from '@/components/chat/message/type/ChatResetButton';

export default {
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    components: {
        LeoAvatar,
        ChatTitle,
        ChatText,
        ChatDarkbox,
        ChatSuggestion,
        ChatButtons,
        ChatDocuments,
        ChatUpload,
        ChatResetButton,
    },
};
</script>

<style lang="scss" scoped>
.chat__message-inner {
    ::v-deep(& > *) {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.chat__message-wrapper {
    display: flex;
}

.chat__avatar {
    margin-right: 2rem;

    @include less-than(md) {
        margin-right: 1.5rem;
    }
}

.chat__message {
    flex-grow: 1;
    max-width: 50rem;
    padding: 2rem;
    background: $lightGray;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: $darkGray;
    @include border-radius($rad);
    @include transition();

    @include less-than(md) {
        font-size: 1.3rem;
        line-height: 1.8rem;
        padding: 1.5rem;
        @include border-radius($rad / 1.5);
    }

    &.--inactive {
        pointer-events: none;
        @include opacity(0.5);
    }

    &:before {
        content: '';
        width: 2rem;
        height: 2rem;
        background: $lightGray;
        position: absolute;
        z-index: -1;
        left: -0.4rem;
        top: 1.5rem;
        @include rotate(45deg);

        @include less-than(md) {
            left: -0.3rem;
            top: 1rem;
        }
    }
}
</style>
