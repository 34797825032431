<template>
    <div class="chat__message-suggestion">
        <chat-title :data="__('Prebehlo všetko v poriadku?')"></chat-title>
        <mc-button secondary @click="closeChatbot()" class="mb-1">
            {{ __('Áno') }}
        </mc-button>
        <mc-button secondary @click="messageFormVisibility(true)" class="mb-1">
            {{ __('Nie') }}
        </mc-button>
        <form @submit.prevent="sendSuggestion()" class="chat__message-suggestion-form mb-1" v-show="messageForm">
            <mc-textarea name="wording" :placeholder="__('Čo môžeme zlepšiť?')" v-model="suggestion"></mc-textarea>
            <mc-error v-show="error">
                {{ error }}
            </mc-error>
            <mc-button primary type="submit" :loading="currentApiState == 'loading'">
                {{ __('Odoslať') }}
            </mc-button>
        </form>
        <mc-button secondary @click="fetchRequiredDocuments()" :loading="currentApiState == 'loading'">
            {{ __('Chcem ešte pokračovať') }}
        </mc-button>
    </div>
</template>

<script>
import ChatTitle from '@/components/chat/message/type/ChatTitle';
import McButton from '@/components/form/McButton';
import McTextarea from '@/components/form/McTextarea';
import McError from '@/components/form/McError';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: {
        ChatTitle,
        McButton,
        McTextarea,
        McError,
    },
    data() {
        return {
            error: null,
            suggestion: '',
            messageForm: false,
        };
    },
    computed: {
        ...mapState(['currentApiState']),
        ...mapState('Chat', ['hash']),
    },
    methods: {
        ...mapMutations('Chat', ['addQuestion']),
        ...mapActions('Chat', ['fetchRequiredDocuments']),
        sendSuggestion() {
            this.error = null;

            if (this.suggestion.length < 10) {
                this.error = this.__('Podnet je príliš krátky, nebudem ho vedieť vyhodnotiť, popíšte prosím podrobnejšie.');
                return false;
            }

            this.submitSuggestion();
        },
        messageFormVisibility(val) {
            this.messageForm = val;
        },
        closeChatbot() {
            this.messageFormVisibility(false);
            this.addQuestion({
                blocks: [{ type: 'ChatText', data: 'Ďakujem a prajem príjemný deň. Teraz môžete zavrieť prehliadač' }],
            });
            this.addQuestion({
                blocks: [
                    {
                        type: 'ChatText',
                        data: 'V prípade ak potrebujete poslať ešte nejaké dokumenty, môžete tak urobiť pomocou voľby „Začať znova“ – na stlačenie sa formulár spustí znova, ale už s označenými dokumentami, ktoré boli vložené.',
                    },
                ],
            });
            this.addQuestion({
                blocks: [
                    {
                        type: 'ChatResetButton',
                    },
                ],
            });
        },
        async submitSuggestion() {
            let xml = `<xml-gsl-chatbot-suggestion xmlns="www.ais.cz" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="www.ais.cz XmlGslChatbotSuggestion.xsd">
                        <wording>${this.suggestion}</wording>
                    </xml-gsl-chatbot-suggestion>`;
            try {
                await window.$axios.post(`${this.hash}/suggestion`, xml, {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                });

                this.addQuestion({
                    blocks: [
                        { type: 'ChatTitle', data: 'Ďakujem' },
                        { type: 'ChatText', data: 'všetko bolo odoslané do poisťovne, teraz môžete chat so mnou ukončiť.' },
                        {
                            type: 'ChatButtons',
                            data: [
                                {
                                    text: 'Chcem ešte pokračovať',
                                    action: 'Chat/fetchRequiredDocuments',
                                    params: null,
                                    type: 'primary',
                                },
                            ],
                        },
                    ],
                });
            } catch (error) {
                throw new Error('Submit Suggestion ' + error.error + ' / msgCode: ' + error.msgCode);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-suggestion-form {
    & > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
