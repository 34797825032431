<template>
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<style lang="scss" scoped>
.lds-ring {
    top: 50%;
    margin-top: -1rem;
    right: 2rem;
    position: absolute;
    width: 2rem;
    height: 2rem;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.1rem;
    border: 0.1rem solid $white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $white transparent transparent transparent;

    .--secondary & {
        border: 0.1rem solid $red;
        border-color: $red transparent transparent transparent;
    }
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
