const globalVueMixins = {
    methods: {
        __(string) {
            return string;
        },
        parseXmlToJson(xml) {
            const json = {};
            for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
                const key = res[1] || res[3];
                const value = res[2] && this.parseXmlToJson(res[2]);
                json[key] = (value && Object.keys(value).length ? value : res[2]) || null;
            }
            return json;
        },
    },
};

export default globalVueMixins;
