<!-- eslint-disable -->
<template>
    <div class="chat__header">
        <div class="chat__logo"></div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    created() {},
    mounted() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.chat__logo {
    $k: 1.5;
    width: 5.8 * $k + rem;
    height: 4.8 * $k + rem;
    min-height: 4.8 * $k + rem;
    background: url($basePath + 'icons/logo.svg') no-repeat center;
    background-size: 100% auto;
    margin: 3rem auto;

    @include less-than(md) {
        $k: 0.8;
        width: 5.8 * $k + rem;
        height: 4.8 * $k + rem;
        min-height: 4.8 * $k + rem;
        margin: 1rem auto;
    }
}
</style>
