<template>
    <div class="chat__message-custom-btns mt-2">
        <mc-button
            v-for="(button, idx) in data"
            :key="idx"
            :primary="button.type == 'primary'"
            :secondary="button.type == 'secondary'"
            @click="handleClick(button)"
            class="chat__message-custom-btn"
        >
            {{ button.text }}
        </mc-button>
    </div>
</template>

<script>
import McButton from '@/components/form/McButton';
export default {
    components: {
        McButton,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    methods: {
        handleClick(button) {
            if (button.action) {
                this.$store.dispatch(button.action);
            } else if (button.mutation) {
                this.$store.commit(button.mutation, button.params);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-custom-btns {
    margin: -0.5rem 0;
}

.chat__message-custom-btn {
    margin: 0.5rem 0;
}
</style>
