<template>
    <div class="chat__message-text">
        {{ data }}
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-text {
}
</style>
