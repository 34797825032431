<!-- eslint-disable -->
<template>
    <main class="main">
        <div class="container">
            <router-view />
        </div>
    </main>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    created() {},
    mounted() {
        window.addEventListener('resize', this.addVh);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.addVh);
    },
    methods: {
        addVh() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
    },
};
</script>

<style lang="scss">
.main {
    width: 100%;
    max-width: $pageWidth;
    margin: 0 auto;
}
</style>
