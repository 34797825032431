<template>
    <strong class="chat__message-title">
        {{ data }}
    </strong>
</template>

<script>
export default {
    props: {
        data: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-title {
    display: block;
    margin-bottom: 1rem;
}
</style>
