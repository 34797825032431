<template>
    <div class="chat">
        <div class="chat__content" ref="chatbot">
            <chat-header />
            <div class="chat__content-inner">
                <div v-for="(question, idx) in questions" :key="idx">
                    <chat-message :question="question" />
                </div>
                <chat-typing v-show="isLoading" />
                <chat-error v-if="isError" />
            </div>
        </div>
    </div>
</template>

<script>
import ChatHeader from '@/components/chat/ChatHeader';
import ChatMessage from '@/components/chat/message/ChatMessage';
import ChatTyping from '@/components/chat/ChatTyping';
import ChatError from '@/components/chat/ChatError';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

export default {
    props: ['hash'],
    components: {
        ChatMessage,
        ChatTyping,
        ChatHeader,
        ChatError,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.bootApp();
    },
    computed: {
        ...mapState('Chat', ['currentState', 'questions']),
        ...mapGetters(['isLoading', 'isError']),
    },
    methods: {
        ...mapActions('Chat', ['fetchIntro', 'fetchAllowedFileTypes']),
        ...mapMutations('Chat', ['setHash', 'resetState']),
        bootApp() {
            this.resetState();
            this.setHash(this.hash);
            this.fetchAllowedFileTypes();
            this.fetchIntro();
            this.scrollToBottom();
        },
        scrollToBottom() {
            this.$nextTick(() => {
                // this.$refs.chatbot.scrollTop = this.$refs.chatbot.scrollHeight;
                this.$refs.chatbot.scrollTo({ top: this.$refs.chatbot.scrollHeight, behavior: 'smooth' });
            });
        },
    },
    watch: {
        questions: {
            deep: true,
            handler() {
                //scroll to bottom of chatbot when question is generated
                this.scrollToBottom();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.chat {
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    left: 0;
    top: 0;
}

.chat__content {
    width: 100%;
    height: 100%;
    padding-bottom: 8rem;
    overflow-x: hidden;
    overflow: auto;

    @include less-than(md) {
        padding: 0 1.5rem 3rem;
    }
}

.chat__content-inner {
    max-width: 57rem;
    margin: auto;

    &:deep(> *) {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
