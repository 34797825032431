<template>
    <div class="chat__message-wrapper">
        <div class="chat__avatar">
            <leo-avatar />
        </div>
        <typing-loader />
    </div>
</template>

<script>
import LeoAvatar from '@/components/chat/LeoAvatar';
import TypingLoader from '@/components/base/TypingLoader';
export default {
    components: {
        LeoAvatar,
        TypingLoader,
    },
};
</script>

<style lang="scss" scoped>
.chat__message-wrapper {
    display: flex;
}

.chat__avatar {
    margin-right: 2rem;

    @include less-than(md) {
        margin-right: 1.5rem;
    }
}
</style>
