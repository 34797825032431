<template>
    <div class="chat__message-docs">
        <mc-button primary doc v-for="doc in data" :key="doc.docId" @click="showUploadForm(doc)" :fulfilled="doc.fulfilled">
            <strong>{{ doc.name }}</strong>
            <span class="pr-2">{{ doc.note }}</span>
        </mc-button>
        <mc-button secondary @click="showSuggestionForm()" v-show="isAnyDocumentFulfilled">
            {{ __('Dokončiť') }}
        </mc-button>
    </div>
</template>

<script>
import McButton from '@/components/form/McButton';
import { mapMutations } from 'vuex';

export default {
    components: {
        McButton,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    computed: {
        isAnyDocumentFulfilled() {
            let isFulfilled = false;

            this.data.forEach((doc) => {
                if (doc.fulfilled) {
                    isFulfilled = true;
                }
            });

            return isFulfilled;
        },
    },
    methods: {
        ...mapMutations('Chat', ['addQuestion']),
        showUploadForm(doc) {
            this.addQuestion({
                blocks: [
                    { type: 'ChatTitle', data: 'Ako si prajete nahrať dokument?' },
                    { type: 'ChatUpload', data: doc },
                ],
            });
        },
        showSuggestionForm() {
            this.addQuestion({
                blocks: [
                    {
                        type: 'ChatText',
                        data: 'Dokumenty boli odoslané do poisťovne. Teraz ich predám likvidátorovi, ktorý ich spracuje a v prípade potreby Vás bude kontaktovať.',
                    },
                ],
            });
            this.addQuestion({
                blocks: [{ type: 'ChatSuggestion' }],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__message-docs {
    margin-top: 1rem;

    & > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
