import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import globalMixins from './mixins/globalMixins.js';
import $axios from './utilities/axios.js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

window.$axios = $axios;
window.$store = store;

let app = createApp(App);

app.config.globalProperties.$axios = $axios;

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', process.env.VUE_APP_BASE_URL, /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
});

Sentry.attachErrorHandler(app, { logErrors: true });

app.use(store).use(router).mixin(globalMixins).mount('#app');
