<template>
    <button
        class="btn"
        v-bind="$attrs"
        :type="type"
        :class="{
            '--primary': primary,
            '--secondary': secondary,
            '--doc': doc,
            '--fulfilled': fulfilled,
            '--pen': pen,
            '--loading': loading,
        }"
        :disabled="disabled || currentApiState == 'loading'"
    >
        <slot></slot>
        <McSpinner v-if="loading" />
    </button>
</template>

<script>
import { mapState } from 'vuex';
import McSpinner from '@/components/form/McSpinner';

export default {
    inheritAttrs: true,
    props: {
        primary: {
            type: Boolean,
            required: false,
        },
        secondary: {
            type: Boolean,
            required: false,
        },
        doc: {
            type: Boolean,
            required: false,
        },
        fulfilled: {
            type: Boolean,
            required: false,
        },
        pen: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            default: 'button',
        },
    },
    components: {
        McSpinner,
    },
    computed: {
        ...mapState(['currentApiState']),
    },
};
</script>

<style lang="scss" scoped>
.btn {
    display: flex;
    min-height: 5rem;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0.5rem 2rem;
    margin: 0;
    font-weight: $fwBold;
    font-size: 1.4rem;
    line-height: 2.4rem;
    width: 100%;
    position: relative;
    @include transition();
    @include appearance(none);
    @include border-radius($rad / 2);
    @include shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

    @include less-than(md) {
        min-height: 4rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
        padding: 0.5rem 1rem;
    }

    &.--doc,
    &.--pen {
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.8rem;
        padding-left: 5rem;
        position: relative;

        &.--fulfilled {
            background: $gray;
            //pointer-events: none;

            &:after {
                content: '';
                width: 2.4rem;
                height: 2.4rem;
                background: $white;
                position: absolute;
                top: 50%;
                right: 1.3rem;
                mask: url($basePath + 'icons/check.svg') no-repeat center;
                @include translateY(-50%);
                @include transition();
            }
        }

        &:before {
            content: '';
            width: 2.4rem;
            height: 2.4rem;
            background: $white;
            position: absolute;
            top: 50%;
            left: 1.3rem;
            mask: url($basePath + 'icons/document.svg') no-repeat center;
            @include translateY(-50%);
            @include transition();
        }

        &:deep(span) {
            text-align: left;
            font-size: 1.3rem;
            line-height: 1.7rem;
            color: lighten($darkGray, 10);
            font-weight: $fwNormal;
            @include transition();
        }
    }

    &.--pen {
        &:before {
            background: $red;
            mask: url($basePath + 'icons/pen.svg') no-repeat center;
        }
        &:hover {
            &:before {
                background: $white;
            }
        }
    }

    &.--loading {
        pointer-events: none;
        @include opacity(0.8);
    }

    &.--primary {
        color: $white;
        background: $red;

        &:deep(span) {
            color: rgba(255, 255, 255, 0.7);
        }

        &:hover {
            background: $orange;

            &:deep(span) {
                color: $white;
            }
        }

        &:disabled {
            cursor: not-allowed;
            pointer-events: none;
            //color: darken($lightGray, 30);
            //background: darken($lightGray, 10);
        }
    }

    &.--secondary {
        color: $red;
        background: $white;
        border: 0.1rem solid $red;

        &:hover {
            background: $orange;
            border-color: $orange;
            color: $white;

            &:deep(span) {
                color: $white;
            }
        }

        &:disabled {
            cursor: not-allowed;
            pointer-events: none;
            //color: darken($lightGray, 30);
            //background: darken($lightGray, 10);
        }
    }
}
</style>
