<template>
    <div class="leo" :class="{ '--large': large }"></div>
</template>

<script>
export default {
    props: {
        large: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {},
    watch: {},
};
</script>

<style lang="scss" scoped>
.leo {
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
    display: flex;
    background: url($basePath + 'bitmap/leo.png') $lightGray no-repeat center;
    background-size: 100% auto;
    @include border-radius(50%);

    @include less-than(md) {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
    }

    &.--large {
        width: 10rem;
        min-width: 10rem;
        height: 10rem;
        background-color: $white;

        @include less-than(md) {
            width: 7rem;
            min-width: 7rem;
            height: 7rem;
        }
    }
}

.chat__content {
    flex-grow: 1;
    overflow: auto;
}
</style>
