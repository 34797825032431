<template>
    <vue-easy-lightbox scrollDisabled escDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        imgs: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    components: {
        VueEasyLightbox,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {
        handleHide() {
            this.$emit('hide', true);
        },
    },
    watch: {},
};
</script>

<style lang="scss" scoped></style>
