<template>
    <div class="typing-loader">
        <span class="typing-loader__dot"></span>
        <span class="typing-loader__dot"></span>
        <span class="typing-loader__dot"></span>
    </div>
</template>

<style lang="scss" scoped>
.typing-loader {
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    background: $lightGray;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: $darkGray;
    @include border-radius($rad);

    @include less-than(md) {
        height: 4rem;
        padding: 0 1.5rem;
        @include border-radius($rad / 1.5);
    }

    &:before {
        content: '';
        width: 2rem;
        height: 2rem;
        background: $lightGray;
        position: absolute;
        z-index: -1;
        left: -0.4rem;
        top: 1.5rem;
        @include rotate(45deg);

        @include less-than(md) {
            left: -0.3rem;
            top: 1rem;
        }
    }
}

.typing-loader__dot {
    width: 0.8rem;
    height: 0.8rem;
    background-color: darken($lightGray, 10);
    display: inline-block;
    margin: 0.2rem;
    @include border-radius(50%);

    &:nth-child(1) {
        animation: bounce 0.8s infinite cubic-bezier(0.66, 0.36, 0.24, 0.61);
    }
    &:nth-child(2) {
        animation: bounce 0.8s infinite cubic-bezier(0.66, 0.36, 0.24, 0.61) 0.15s;
    }
    &:nth-child(3) {
        animation: bounce 0.8s infinite cubic-bezier(0.66, 0.36, 0.24, 0.61) 0.3s;
    }
}

@keyframes bounce {
    0% {
        @include translateY(0);
    }
    25% {
        @include translateY(0.3rem);
    }
    50% {
        @include translateY(-0.3rem);
    }
    100% {
        @include translateY(0);
    }
}
</style>
