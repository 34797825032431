<template>
    <div class="form-group__wrapper">
        <!-- prettier-ignore -->
        <textarea
                :name="name"
                :disabled="disabled"
                :required="required"
                :readonly="readonly"
                class="form-group__input"
                :placeholder="placeholder ? placeholder : ' '"
                v-model="val"
                @input="$emit('update:modelValue', $event.target.value)">
        </textarea>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        readonly: {
            type: Boolean,
            required: false,
        },
        valid: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            val: this.modelValue,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.form-group__wrapper {
    display: block;
    position: relative;
    width: 100%;
    @include transition();
}

.form-group__input {
    width: 100%;
    height: 100%;
    padding: 1.3rem;
    border: 0;
    font-family: $baseFont;
    font-weight: $fwNormal;
    font-size: 1.4rem;
    color: $darkGray;
    line-height: 2rem;
    resize: vertical;
    background: transparent;
    min-height: 8rem;
    border: 0.1rem solid darken($lightGray, 15);
    cursor: text;
    background: $white;
    @include appearance(none);
    @include transition();

    @include border-radius($rad / 2);

    &:hover,
    &:focus {
        border: 0.1rem solid darken($lightGray, 35);
    }

    .form-group__wrapper.--invalid & {
        color: $red !important;
    }
}
</style>
