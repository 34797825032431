import { createRouter, createWebHistory } from 'vue-router';
import ChatBot from '../views/ChatBot.vue';

const routes = [
    {
        path: '/:hash?',
        name: 'ChatBot',
        component: ChatBot,
        props: true,
    },
    {
        path: '/resttest1/:hash?',
        name: 'ChatBotTest1',
        component: ChatBot,
        props: true,
    },
    {
        path: '/resttest2/:hash?',
        name: 'ChatBotTest2',
        component: ChatBot,
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
