<template>
    <div class="chat__message-wrapper">
        <div class="chat__avatar">
            <leo-avatar />
        </div>
        <div class="chat__message">
            <span v-if="currentApiState.msgCode == '433120'">
                {{ __('Zadaný hashId nebol nájdený alebo už nie je platný.') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '433121'">
                {{ __('Musí byť vybraný aspoň jeden súbor.') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '432143'">
                {{ __('Súbor nemá nastavený názov alebo typ') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '432144'">
                {{ __('Súbor nemá nastavený názov alebo typ') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '433226'">
                {{ __('Zlý formát dokumentu') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '433227'">
                {{ __('Zlý formát dokumentu') }}
            </span>
            <span v-else-if="currentApiState.msgCode == '433493'">
                {{ __('Čas pre odoslanie dokumentov vypršal.') }}
            </span>
            <span v-else>
                {{ __('Služba neodpovedá') }}
            </span>
        </div>
    </div>
</template>

<script>
import LeoAvatar from '@/components/chat/LeoAvatar';
import { mapState } from 'vuex';
export default {
    components: {
        LeoAvatar,
    },
    computed: {
        ...mapState(['currentApiState']),
    },
};
</script>

<style lang="scss" scoped>
.chat__message-wrapper {
    display: flex;
}

.chat__avatar {
    margin-right: 2rem;

    @include less-than(md) {
        margin-right: 1.5rem;
    }
}

.chat__message {
    flex-grow: 1;
    max-width: 50rem;
    padding: 2rem;
    background: $red;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: $white;
    @include border-radius($rad);

    &:deep(a) {
        color: $white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    @include less-than(md) {
        font-size: 1.3rem;
        line-height: 1.8rem;
        padding: 1.5rem;
        @include border-radius($rad / 1.5);
    }

    &:before {
        content: '';
        width: 2rem;
        height: 2rem;
        background: $red;
        position: absolute;
        z-index: -1;
        left: -0.4rem;
        top: 1.5rem;
        @include rotate(45deg);

        @include less-than(md) {
            left: -0.3rem;
            top: 1rem;
        }
    }
}
</style>
