<template>
    <div>
        <div class="mc-file__wrapper">
            <label class="mc-file__upload">
                <input :name="name" type="file" @change="onInputChange" multiple ref="fileInput" />
                <span v-if="files.length == 0">{{ __('Odfotiť alebo vybrať dokument') }}</span>
                <span v-else>{{ __('Pridať ďalší') }}</span>
            </label>
        </div>
        <div class="mc-file__files" v-if="files.length > 0">
            <div class="mc-file__file" v-for="(file, idx) in files" :key="idx">
                <img :src="file.src" v-if="checkIfItsImage(file.file)" @click="showLightBox(file.src)" />
                <div v-else>
                    <div class="mc-file__doc-icon"></div>
                    <div class="mc-file__name">{{ file.file.name }}</div>
                </div>
                <span class="mc-file__delete" @click.prevent="removeFile(idx)">
                    {{ __('Zmazať') }}
                </span>
            </div>
        </div>
        <mc-lightbox :visible="lightBox.visible" :imgs="lightBox.images" :index="lightBox.index" @hide="hideLightBox"></mc-lightbox>

        <mc-error v-show="error" class="mt-1">
            {{ error }}
        </mc-error>
    </div>
</template>

<script>
import Compressor from 'compressorjs';
import McError from '@/components/form/McError';
import McLightbox from '@/components/lightbox/McLightbox';
import { mapState } from 'vuex';

export default {
    props: ['name', 'fileName'],
    emits: ['files'],
    components: {
        McError,
        McLightbox,
    },
    data() {
        return {
            lightBox: {
                index: 0,
                visible: false,
                images: [],
            },
            error: null,
            files: [],
            // prettier-ignore
            extensions: [],
            imageExtensions: ['jpg', 'jpeg', 'png'],
            videoExtensions: ['mp4', 'mov'],
            maxFileSize: 5, //MB
            maxVideoFileSize: 5, //MB
        };
    },
    created() {},
    mounted() {
        const initialAllowedFileTypes = [
            'asc',
            'cdf',
            'csv',
            'diff',
            'docx',
            'eml',
            'jfif',
            'jpe',
            'jpeg',
            'jpg',
            'm4a',
            'mht',
            'mhtml',
            'mp2',
            'mp3',
            'mpega',
            'mpga',
            'msg',
            'nws',
            'pdf',
            'png',
            'pot',
            'rar',
            'rtf',
            'text',
            'tif',
            'tiff',
            'txt',
            'xlsx',
            'xml',
            'xsl',
            'zip',
        ];

        this.extensions = this.allowedFileTypes ? this.allowedFileTypes : initialAllowedFileTypes;
    },
    computed: {
        ...mapState('Chat', ['allowedFileTypes']),
    },
    methods: {
        onInputChange(e) {
            const files = e.target.files;
            Array.from(files).forEach((file) => this.addFile(file));
        },
        addFile(file) {
            this.error = null;

            let that = this;

            if (!file) {
                return;
            }

            //file basic validation, real validation on backend
            let ext = file.name.split('.').pop().toLowerCase();

            if (!this.extensions.includes(ext)) {
                this.error = this.__('Nepodporovaný formát dokumentu');
                return;
            }

            if (this.videoExtensions.includes(ext)) {
                if (file.size / 1000000 > this.maxVideoFileSize) {
                    this.error = this.__(`Presiahnutá maximálna veľkosť súboru ${this.maxVideoFileSize}MB.`);
                    return;
                }
            }
            // else {
            //     if (file.size / 1000000 > this.maxFileSize) {
            //         this.error = this.__(`Presiahnutá maximálna veľkosť súboru ${this.maxFileSize}MB.`);
            //         return;
            //     }
            // }

            const reader = new FileReader();

            if (this.imageExtensions.includes(ext)) {
                // START COMPRESSOR
                new Compressor(file, {
                    quality: 0.5,
                    convertSize: 0, //because of a bug in  library with PNG

                    // The compression process is asynchronous,
                    // which means you have to access the `result` in the `success` hook function.
                    success(result) {
                        reader.onload = (e) => {
                            //create file from blob
                            let newFile = new File([result], file.name);

                            //push file to files
                            that.files.push({ file: newFile, src: e.target.result });
                        };

                        reader.readAsDataURL(result);
                    },
                    error(err) {
                        console.log(err.message);
                    },
                });
                // END COMPRESSOR
            } else {
                reader.onload = (e) => {
                    let newFile = new File([file], file.name);
                    this.files.push({ file: newFile, src: e.target.result });
                };
                reader.readAsDataURL(file);
            }
        },
        removeFile(index) {
            //reset file field, because of problem to upload same file as previous which was deleted
            this.$refs.fileInput.value = '';
            this.files.splice(index, 1);
        },
        updateFiles() {
            this.$emit('files', this.files);
        },
        checkIfItsImage(file) {
            let ext = file.name.split('.').pop().toLowerCase();

            if (!this.imageExtensions.includes(ext)) {
                return false;
            }

            return true;
        },
        showLightBox(src) {
            this.lightBox.index = this.lightBox.images.indexOf(src);
            this.lightBox.visible = true;
        },
        hideLightBox() {
            this.lightBox.visible = false;
        },
    },
    watch: {
        files: {
            deep: true,
            handler: function () {
                this.updateFiles();

                //images for lightBox
                this.lightBox.images = [];
                this.files.forEach((file) => {
                    if (this.checkIfItsImage(file.file)) {
                        this.lightBox.images.push(file.src);
                    }
                });
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.mc-file__wrapper {
}

.mc-file__upload {
    display: flex;
    min-height: 5rem;
    align-items: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0.5rem 2rem 0.5rem 5rem;
    margin: 0;
    font-weight: $fwBold;
    font-size: 1.4rem;
    line-height: 2.4rem;
    width: 100%;
    color: $red;
    background: $white;
    border: 0.1rem solid $red;
    position: relative;
    @include transition();
    @include appearance(none);
    @include border-radius($rad / 2);
    @include shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

    @include less-than(md) {
        min-height: 4rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
    }

    &:hover {
        background: $orange;
        color: $white;
        border-color: $orange;

        &:before {
            background: $white;
        }
    }

    &:before {
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        background: $red;
        position: absolute;
        top: 50%;
        left: 1.3rem;
        mask: url($basePath + 'icons/upload.svg') no-repeat center;
        @include translateY(-50%);
        @include transition();
    }

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        pointer-events: none;
        @include opacity(0);
    }
}

.mc-file__files {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 16rem;
    align-items: center;
    padding: 1rem 0;
    margin: -0.5rem;

    @include less-than(md) {
        min-height: 12rem;
    }
}

.mc-file__file {
    max-width: calc(100% / 2 - 1rem);
    min-width: calc(100% / 2 - 1rem);
    margin: 0.5rem;
    height: 14rem;
    background: $white;
    border: 0.1rem solid darken($lightGray, 10);
    overflow: hidden;
    position: relative;
    @include border-radius($rad / 2);

    @include less-than(md) {
        height: 10rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: zoom-in;
    }
}

.mc-file__doc-icon {
    width: 4rem;
    height: 4rem;
    background: darken($lightGray, 10);
    mask: url($basePath + 'icons/document.svg') no-repeat center;
    mask-size: 100% auto;
    position: absolute;
    left: 50%;
    margin-left: -2rem;
    top: 50%;
    margin-top: -4rem;
}

.mc-file__name {
    color: $darkGray;
    font-size: 1.1rem;
    position: absolute;
    bottom: 3rem;
    left: 5%;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @include less-than(md) {
        bottom: 2.5rem;
    }
}

.mc-file__delete {
    width: 100%;
    height: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $red;
    color: $white;
    font-weight: $fwBold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    @include transition();

    &:hover {
        background: $orange;
    }
}
</style>
