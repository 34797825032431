import _ from 'lodash';

const defaultState = {
    hash: null,
    allowedFileTypes: null,
    questions: [],
};

const state = _.cloneDeep(defaultState);

const getters = {
    /* empty */
};

const actions = {
    async fetchIntro({ commit, state }) {
        try {
            if (_.isEmpty(state.questions)) {
                let response = await window.$axios.get(`${state.hash}/intro`),
                    intro = response.data || {};

                commit('addQuestion', {
                    avatar: true,
                    blocks: [
                        { type: 'ChatTitle', data: 'Dobrý deň,' },
                        { type: 'ChatText', data: 'volám sa Leon a pomôžem Vám so zaslaním dokumentov do poisťovne.' },
                        { type: 'ChatDarkbox', data: intro.concern },
                        { type: 'ChatText', data: intro.note ? intro.note : null },
                        {
                            type: 'ChatButtons',
                            data: [
                                {
                                    text: 'Rozumiem, pokračujme',
                                    type: 'primary',
                                    action: 'Chat/fetchRequiredDocuments',
                                },
                            ],
                        },
                    ],
                });
            }
        } catch (error) {
            commit('resetQuestions');
            throw new Error('Fetch Intro ' + error.error + ' / msgCode: ' + error.msgCode);
        }
    },
    async fetchRequiredDocuments({ commit, state }) {
        try {
            let response = await window.$axios.get(`${state.hash}/requiredDocs`),
                documents = response.data.docs || [];

            commit('addQuestion', {
                blocks: [
                    { type: 'ChatTitle', data: 'Požadované dokumenty' },
                    { type: 'ChatDocuments', data: documents },
                ],
            });
        } catch (error) {
            throw new Error('Fetch RequiredDocs ' + error.error + ' / msgCode: ' + error.msgCode);
        }
    },
    async fetchAllowedFileTypes({ commit }) {
        try {
            let response = await window.$axios.get(process.env.VUE_APP_ALLOWED_FILE_TYPES),
                fileTypes = response.data.fileTypes || [];

            var allowedFileTypes = [];

            fileTypes.forEach((item) => {
                if (item.extensions) {
                    item.extensions.forEach((extension) => {
                        allowedFileTypes.push(extension);
                    });
                }
            });
            commit('setAllowedFileTypes', allowedFileTypes);
        } catch (error) {
            throw new Error('Fetch AllowedFileTypes ' + error.error + ' / msgCode: ' + error.msgCode);
        }
    },
};

const mutations = {
    resetState(state) {
        for (var key in defaultState) {
            if (key != 'questions') {
                state[key] = defaultState[key];
            }
        }
    },
    resetQuestions(state) {
        state.questions = [];
    },
    setHash(state, data) {
        state.hash = data;
    },
    setAllowedFileTypes(state, data) {
        state.allowedFileTypes = data;
    },
    addQuestion(state, obj) {
        //inactive state for old questions
        state.questions.forEach((question) => {
            question['state'] = 'inactive';
        });

        state.questions.push(obj);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
